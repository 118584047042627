<template>
  <div>
    <CCard>
        <CCardHeader>
          <h1>{{ quiz.title }} </h1>
        </CCardHeader>
        <CCardBody>
          <div class="row mb-5" v-if="quiz">
            <div class="col-md-12">
              {{ quiz.correct_answers }} / {{ quiz.questions.length  }} = {{ quiz.score }}% | Min. {{ quiz.min_score }}<br>
              <div v-if="quiz.pass" class='text-success'>
                APROBADO
              </div>
              <div v-if="!quiz.pass" class='text-danger'>
                REPROBADO
              </div>
            </div>
          </div>

          <div class="row mb-3 py-2" v-for="(question, index) in quiz.questions" :key="index" v-bind:class="{ 'bg-success': question.is_correct, 'bg-danger': !question.is_correct }">
            <div class="col-md-12">
              <h3>{{ question.question }}</h3>
              <div v-for="(option, index) in question.options" :key="index">
                <input disabled type="radio" v-model="question.answer" v-bind:value="option.id">
                <label class="ml-2">{{ option.option }}</label>
                <br>
              </div>
            </div>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="() => $router.push('/course') ">
              Salir
          </CButton>
        </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
export default {
  name: 'Quiz',
  props: [
    'id'
  ],
  data: function () {
    return {
        course: {},
        quiz: {},
        show: true,
        isCollapsed: true,
        loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
    const token = store.state.user.token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/quiz/' + this.$route.params.id + '/results', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.quiz = response.data;
        })
  },
  methods: {
    submit() {
      const token = store.state.user.token;
      axios
        .post(process.env.VUE_APP_BACKEND_URL+'/api/quiz/' + this.quiz.id + '/attempt', this.quiz, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          console.log(response.data);
        })
    }
  }
}
</script>
